@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap");

body {
  line-height: 1.6;
}
img {
  display: block;
  max-width: 100%;
}

span {
  color: #ffc107;
}

body {
  font-family: "Nunito", sans-serif;
  letter-spacing: 1px;
}

h1 {
  font-size: 30px;
  font-weight: 700;
}

h2 {
  font-size: 30px;
  font-weight: 700;
}

h3 {
  font-size: 24px;
  font-weight: 700;
}

h4 {
  font-size: 16px;
  font-weight: 400;
}

h5 {
  font-size: 14px;
  font-weight: 400;
}

h6 {
  font-size: 12px;
  font-weight: 400;
}

p {
  font-size: 18px;
  font-weight: 400;
}

a {
  font-size: 16px;
  font-weight: 400;
  text-decoration: none !important;
}

.at-button {
  border-radius: 3px 15px;
  display: inline-block;
  min-width: 180px;
  text-decoration: none !important;
  padding: 12px 0;
  text-align: center;
}

.at-button--transparent {
  background: none;
  color: #fff !important;
  border: 1px solid #fff;
}

.at-button--background {
  background: #ffc107;
  color: #283c86 !important;
  border: 1px solid #ffc107;
}

@media only screen and (min-width: 768px) {
  h1 {
    font-size: 42px;
  }

  h2 {
    font-size: 42px;
  }

  h3 {
    font-size: 24px;
  }

  h4 {
    font-size: 18px;
  }

  h5 {
    font-size: 16px;
  }

  h6 {
    font-size: 14px;
  }

  p {
    font-size: 20px;
  }

  a {
    font-size: 18px;
  }
}
