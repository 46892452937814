.at-header--wrapper {
  position: fixed;
  width: 100%;
  background: transparent;
  box-shadow: none;
  top: 0;
  left: 0;
  right: 0;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  z-index: 999999;
  background-color: #283c86;
  background-image: linear-gradient(-29deg, #45a247, #283c86) !important;
  background-image: -o-linear-gradient(
    -29deg,
    #283c86 0,
    #45a247 100%
  ) !important;
  background: linear-gradient(-29deg, #45a247, #283c86) !important;
}

.at-navbar {
  padding: 16px!important;
}

.at-navbar-collapse {
  flex-grow: unset !important;
}

.at-navbar-expand {
  flex-wrap: wrap !important;
  justify-content: space-between !important;
}

.at-header--logo {
  width: 100%;
  max-width: 150px;
}

.at-hemburger {
  display: block;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 8px;
  right: 0px;
  z-index: 9;
}

.at-hemburger-inner-wrap {
  position: relative;
  width: 20px;
  height: 20px;
}

.at-header-toggler-bar {
  width: 20px;
  border-radius: 5px;
  height: 2px;
  background: #fff;
  top: 0;
  position: absolute;
}

.at-header-toggler-bar:nth-child(2) {
  top: unset;
  width: 15px;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.at-header-toggler-bar:nth-child(3) {
  top: unset;
  bottom: 0;
}

.at-menu-section--list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: none;
}

.at-navlinks {
  color: #fff !important;
  text-decoration: none;
}

.at-header-menu-section {
  position: relative;
}

@media only screen and (min-width: 768px) {
  .at-header-menu-section {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }

  .at-hemburger {
    display: none;
  }

  .at-menu-section--list {
    display: flex;
    flex-wrap: wrap;
    max-width: calc(100% - 170px);
  }
}

@media only screen and (min-width: 992px) {
  .at-navlinks span {
    width: 30px;
    height: 3px;
    background-color: #ffc107;
    display: block;
    margin-top: 2px;
    transition: all 0.3s linear;
    border-radius: 4px;
  }

  .at-navlinks:hover span {
    width: 100%;
    background-color: #000;
    transition: all 0.3s linear;
  }

  .at-navlinks {
    margin-right: 9px;
  }
}