.at-home__banner-wrapper {
    padding-bottom: 50px;
  }
  
  .at-home__bg-container {
    background-image: url("./../../images/banner-bg.png");
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 180px 0;
    color: #fff;
    overflow: hidden;
    position: relative;
    z-index: 99;
  }
  
  .at-home__banner-wrapper .at-home__bg-container:after {
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
    z-index: -1;
    content: "";
    display: block;
    background-image: url("./../../images/wave-pattern.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 0;
  }
  
  .at-banner__overlay {
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
    z-index: -1;
    background-color: #283c86;
    background-image: linear-gradient(-129deg, #45a247, #283c86) !important;
    background-image: -o-linear-gradient(
      -29deg,
      #283c86 0,
      #45a247 100%
    ) !important;
    background: linear-gradient(-129deg, #45a247, #283c86) !important;
    top: 0;
    bottom: 0;
    width: 100%;
    opacity: 0.65;
  }
  
  .at-banner__row {
    align-items: center;
  }
  
  .at-home__banner--heading {
    text-shadow: 0 4px 4px rgb(0 0 0 / 22%);
    margin-bottom: 20px;
  }
  
  .at-home__banner--text {
    margin-bottom: 15px;
  }
  
  .at-home__banner-list {
    padding: 0;
    list-style: none;
    margin-bottom: 40px;
  }
  
  .at-home__banner--list-items {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .at-home__banner--list-items span {
    margin-right: 10px;
  }
  
  .at-home__banner--list-items h5 {
    margin: 0;
  }
  
  .at-button__wrapper a:nth-child(1) {
    margin-right: 10px;
  }
  
  .at-banner__img {
    margin-top: 50px;
  }
  
  .at-banner__img img {
    margin: 0 auto;
  }
  
  .at-home__product-wrapper {
    padding-bottom: 50px;
  }
  
  .at-product__pattern-heading {
    position: relative;
    margin-bottom: 100px;
  }
  
  .at-home__product--heading {
    text-shadow: 0 4px 4px rgb(0 0 0 / 22%);
    max-width: 700px;
    margin: 0 auto;
    text-align: center;
    width: 100%;
  }
  
  .at-product__pattern-heading img {
    position: absolute;
    top: 0;
    right: 113px;
    z-index: -1;
  }
  
  .at-product__image-section {
    margin-bottom: 100px;
  }
  
  .at-product__image-section .at-prod__about {
    position: relative;
  }
  
  .at-product__image-section .at-prod__overlay-bg {
    background: rgba(69, 162, 71, 0.4);
    padding: 40px 20px;
    margin-top: 50px;
    text-align: center;
    border-radius: 20px;
  }
  
  .at-prod__image img {
    width: 100%;
  }
  
  .at-prod__overlay-bg h3 {
    padding: 16px 0;
  }
  
  .at-circle-2 {
    position: absolute;
    bottom: -85px;
    right: 0;
    z-index: -1;
  }
  
  .at-product__image-section-alt .at-prod__overlay-bg {
    background: rgba(69, 162, 71, 0.4);
    padding: 40px 20px;
    margin-top: 0;
    text-align: center;
    border-radius: 20px;
  }
  
  .at-product__image-section-alt .at-prod__overlay-bg h3 {
    padding: 16px 0;
  }
  
  .at-product__image-section-alt .at-circle-3 {
    position: absolute;
    bottom: -75px;
    z-index: -1;
  }
  
  .at-product__image-section-alt .at-prod__about {
    position: relative;
  }
  
  .at-product__image-section-alt .at-prod__image {
    margin-top: 70px;
  }
  
  .at-product__image-section-alt .at-prod__image img {
    width: 100%;
  }
  
  .at-home__card-wrapper {
    padding-bottom: 50px;
  }
  
  .at-home__card-row {
    display: flex;
    flex-wrap: wrap;
  }
  
  .at-whyus__card {
    background: #f8f9fa;
    box-shadow: 0 8px 25px #dce0e3;
    position: relative;
    margin: 0 30px 50px;
  }
  
  .at-whyus--icon {
    position: absolute;
    width: 50px;
    height: 50px;
    left: -22px;
    top: 19px;
  }

  .at-about__whyus--heading {
    text-shadow: 0 4px 4px rgb(0 0 0 / 22%);
    margin-bottom: 50px;
    text-align: center;
}
  
  .at-card__inner-container {
    padding: 40px 40px 50px;
  }
  
  .at-whyus--heading {
    font-weight: 600;
    position: relative;
    margin-bottom: 30px;
  }
  
  .at-whyus--heading:after {
    position: absolute;
    content: "";
    width: 75px;
    background: #000;
    height: 2px;
    bottom: -15px;
    left: 0;
  }
  
  .at-whyus--para {
    font-weight: 700;
    margin-bottom: 20px;
  }
  
  
  
  .at-home__our-team {
    /* padding-bottom: 50px; */
  }
  
  .at-team-inner-wrap {
    background: linear-gradient(
      174.04deg,
      rgba(69, 162, 71, 0.8) -2.64%,
      rgba(40, 60, 134, 0.8) 102.52%
    );
    padding: 80px 0;
  }
  
  .at-team--row {
    align-items: center;
  }
  
  .at-team-desc {
    margin-bottom: 50px;
  }
  
  .at-team--heading {
    color: #fff;
    margin-bottom: 25px;
  }
  
  .at-team-description {
    color: #fff;
    margin-bottom: 30px;
  }
  
  .at-team-desc a {
    padding-left: 30px;
    padding-right: 30px;
  }
  
  .at-team-member__container {
    display: block;
    max-width: 100%;
  }
  
  .at-member {
    margin-bottom: 50px;
    cursor: pointer;
    flex: 0 0 33.33%;
    transition: all 0.3s linear;
  }
  
  .at-member img {
    width: 100%;
    padding: 0 10px;
  }
  
  .at-partner-cta-wrapper {
    position: relative;
  }
  
  .at-partner-cta--section {
    padding: 80px 0;
  }
  
  .at-partner-cta--content {
    width: 100%;
    max-width: 458px;
    margin: 0 auto;
    text-align: center;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  
  .at-img__bl {
    position: absolute;
    left: -50%;
    display: none;
  }
  
  .at-partnet__cta--title {
    text-shadow: 0 4px 4px rgb(0 0 0 / 22%);
    margin-bottom: 40px;
  }
  
  .at-partner-cta--content a {
    padding-left: 30px;
    padding-right: 30px;
  }
  
  .at-img__tr {
    position: absolute;
    right: -50%;
    display: none;
  }

  .at-button__wrapper {
    display: block;
  }

  .at-button--transparent {
    margin-bottom: 15px;
  }
  
  @media only screen and (min-width: 1200px) {

    .at-whyus__card:nth-child(3) {
      margin-right: 0;
    }
    
    .at-product__image-section .at-prod__overlay-bg {
      left: -15px !important;
    }
  
    .at-product__image-section-alt .at-prod__overlay-bg {
      right: -15px;
    }
  
    .at-whyus__card {
      flex: 0 0 calc(33.33% - 30px);
      max-width: calc(33.33% - 30px);
      margin: 0 45px 30px 0;
    }
  
    .at-whyus--icon {
      width: 80px;
      height: 80px;
      left: -34px;
    }
  
    .at-card__inner-container {
      padding: 40px 70px 50px;
    }
  
    .at-team-desc {
      margin-bottom: 0;
    }
  }
  
  @media only screen and (min-width: 992px) {
    .at-home__banner-wrapper,
    .at-home__product-wrapper {
      padding-bottom: 100px;
    }
  
    .at-product__image-section .at-prod__overlay-bg {
      position: absolute;
      top: 50%;
      left: -14px;
      right: 0;
      transform: translateY(-50%);
      margin-top: 0;
      text-align: left;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  
    .at-product__image-section-alt .at-prod__overlay-bg {
      position: absolute;
      top: 50%;
      right: -14px;
      left: 0;
      transform: translateY(-50%);
      margin-top: 0;
      text-align: left;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
    }
  
    .at-banner__img img {
      margin: 0 0 0 auto;
    }
  
    .at-product__image-section-alt .at-prod__image {
      margin-top: 0;
    }
  
    .at-home__card-wrapper {
      padding-bottom: 100px;
    }
  
    .at-home__our-team {
      padding-bottom: 100px;
    }
  
    .at-partner-cta-wrapper {
      padding-bottom: 100px;
    }
  
    .at-img__tr,
    .at-img__bl {
      display: block;
    }
  }
  
  @media only screen and (min-width: 768px) {
    .at-product__image-section .at-prod__overlay-bg {
      padding: 80px 60px;
    }
  
    .at-product__image-section-alt .at-prod__overlay-bg {
      padding: 80px 60px;
    }
  }
  
  @media only screen and (min-width: 576px) {
    .at-team-member__container {
      display: flex;
      flex-wrap: wrap;
    }
  
    .at-member {
      max-width: 33.33%;
      margin-bottom: 20px;
    }

    .at-button__wrapper {
      display: flex;
    }

    .at-button--transparent {
      margin-bottom: 0px;
    }
  }