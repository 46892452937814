.at-footer .at-footer-bg-pattern {
  padding: 390px 0 100px;
  color: #fff;
  overflow: hidden;
  position: relative;
  z-index: 99;
  background: #000;
  line-height: 1.6;
}

.at-footer .at-footer-bg-pattern .at-footer--overlay {
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  z-index: -1;
  background-color: #000;
  top: 0;
  bottom: 0;
  width: 100%;
  opacity: 0.65;
}

.at-footer-bg-pattern:after {
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  z-index: -1;
  content: "";
  display: block;
  background: url("./../../images/wave-pattern.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  bottom: 0;
  transform: rotate(180deg);
}

.at-footer--logo {
  margin-bottom: 30px;
  margin-top: -80px;
}

.at-footer__brand-subject {
  color: #fff;
  margin-bottom: 70px;
  line-height: 1.6;
}

.at-footer__subscribe-us--heading {
  color: #fff;
  font-weight: 800;
  line-height: 1.6;
  margin-bottom: 25px;
}

.at-footer__subscribe-us--text {
  margin-bottom: 20px;
  line-height: 1.6;
}

.at-footer--subscribe-form {
  display: flex;
  position: relative;
  margin-bottom: 10px;
}

.at-footer--subscribe-form input {
  width: 100%;
  border-radius: 10px;
  padding: 10px 0 10px 10px;
  border: none;
}

.at-footer--subscribe-form button {
  position: absolute;
  border: none;
  background: #ffc107;
  padding: 0 20px;
  border-radius: 10px;
  right: 0;
  top: 0;
  bottom: 0;
}

.at-footer__subscribe-us--heading {
  color: #fff;
  font-weight: 800;
  line-height: 1.6;
  margin-bottom: 25px;
}

.at-footer__subscribe-us--text {
  margin-bottom: 20px;
  line-height: 1.6;
}

.at-footer__subscribe-us--text a {
  color: #fff !important;
  opacity: 0.5;
  transition: all 0.3s linear;
  display: block;
  text-decoration: none;
}

.at-footer__copyright--text a {
  color: #fff !important;
  text-decoration: none;
  opacity: 0.5;
  transition: all 0.3s linear;
}

.at-footer__copyright--text a:hover {
  opacity: 1;
  transition: all 0.3s linear;
}

.at-footer__terms--text a {
  color: #fff !important;
  text-decoration: none;
  opacity: 0.5;
  transition: all 0.3s linear;
}

.at-footer__terms--text a:hover {
  opacity: 1;
  transition: all 0.3s linear;
}

.at-footer__privacy--text a {
  color: #fff !important;
  text-decoration: none;
  opacity: 0.5;
  transition: all 0.3s linear;
}

.at-footer__privacy--text a:hover {
  opacity: 1;
  transition: all 0.3s linear;
}

.at-footer__privacy--text {
  text-align: left;
}

.at-footer__subscribe-us--text a:hover {
  opacity: 1;
  transition: all 0.3s linear;
}

@media only screen and (min-width: 992px) {
  .col-lg-6.at-footer-col2 {
    padding-left: 75px;
  }

  .at-footer__terms--text {
    text-align: center;
  }
}

@media only screen and (min-width: 1200px) {
  .col-lg-6.at-footer-col2 {
    padding-left: 125px;
  }

  .at-footer__privacy--text {
    text-align: right;
  }
}
