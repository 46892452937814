.img-div img {
    width: 100%;
}

.main-heading {
    text-shadow: 0 4px 4px rgb(0 0 0 / 22%);
    margin-bottom: 40px;
}

button.btn.mx-2:focus {
    color: #45a247;
    box-shadow: none;
}