.at-career__banner-wrapper {
  padding-bottom: 50px;
  padding-top: 100px;
}

.at-career-banner__row {
  align-items: center;
}

.at-career__banner--heading {
  text-shadow: 0 4px 4px rgb(0 0 0 / 22%);
  margin-bottom: 20px;
  font-size: 42px;
}

.at-career__banner--text {
  margin-bottom: 30px;
  width: 100%;
}

.at-career-banner__img {
  margin-top: 50px;
}

.at-career-banner__img img {
  margin: 0 auto;
}

.at-career--hiring-wrapper {
  padding-bottom: 50px;
  padding-top: 50px;
  background-color: #f7f9fb;
}

.row.at-career-hiring-row {
  margin: 0 10px;
}

.at-career--heading {
  text-shadow: 0 4px 4px rgb(0 0 0 / 22%);
  margin-bottom: 70px;
  text-align: center;
}

.at-career-hiring-row {
  border-radius: 24px;
  overflow: hidden;
}

.at-career--grids {
  cursor: pointer;
}

.at-career-position1 {
  background-color: rgba(40, 60, 134, 0.5);
  position: relative;
  background-image: url("./../../images/hiring-pattern1.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 200px 0;
}

.at-career-content-wrap {
  position: absolute;
  top: 54%;
  transform: translateY(-20%);
  left: 0;
  bottom: 0;
  right: 0;
  transition: 0.3s ease-in-out;
  text-align: center;
}

.at-career--profile {
  color: #fff;
}

.at-career--work {
  opacity: 0;
  transition: 0.3s ease-in-out;
  color: #fff;
}

.at-career--grids:hover .at-career-content-wrap {
  transform: translateY(-50%);
  transition: 0.3s ease-in-out;
}

.at-career--grids:hover .at-career--work {
  opacity: 1;
  transition: 0.3s ease-in-out;
}

.at-career-position2 {
  background-color: rgba(255, 193, 7, 0.5);
  position: relative;
  background-image: url("./../../images/hiring-pattern2.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 200px 0;
}

.at-career-position3 {
  background-color: rgba(69, 162, 71, 0.5);
  position: relative;
  background-image: url("./../../images/hiring-pattern3.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 200px 0;
}

.at-career__recruitment-wrapper {
  padding-bottom: 50px;
  padding-top: 50px;
}

.at-recruitment-section--heading {
  text-shadow: 0 4px 4px rgb(0 0 0 / 22%);
  margin-bottom: 70px;
  text-align: center;
}

.at-career__recruitment-col {
  margin-bottom: 90px;
}

.at-career__recritment--process {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background: #f8f9fa;
  box-shadow: 0 8px 25px #dce0e3;
  padding: 30px;
}

.at-career__recritment--process {
  background: transparent;
  box-shadow: none;
  padding: 0;
}

.at-recruitment-img {
  width: 100%;
  max-width: 60%;
  margin: 0 auto;
  margin-bottom: 60px;
}

.at-recruitment-img img {
  width: 100%;
  /* max-width: 40%; */
  /* margin: 0 auto; */
  /* margin-bottom: 60px; */
}

.at-career-dots-right {
  display: none;
}

.at-career__recritment--process {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background: #f8f9fa;
  box-shadow: 0 8px 25px #dce0e3;
  padding: 30px;
}

.at-career__recruitment-col:nth-child(2n) .at-career__recritment--process {
  justify-content: flex-end;
}

.at-career-dots-right,
.at-career-dots-left {
  display: none;
}

.at-career-form-wrapper {
  padding-bottom: 50px;
  padding-top: 50px;
  background-image: url("./../../images/wave-pattern.png");
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
}

.at-career-form-wrapper:after {
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  z-index: -1;
  background-image: linear-gradient(
    154.6deg,
    rgba(69, 162, 71, 0.18) 14.92%,
    rgba(40, 60, 134, 0.18) 80.72%
  );
  background: linear-gradient(
    154.6deg,
    rgba(69, 162, 71, 0.18) 14.92%,
    rgba(40, 60, 134, 0.18) 80.72%
  );
  top: 0;
  bottom: 0;
  width: 100%;
  opacity: 0.65;
  content: "";
}

.at-career-form-container {
  padding: 60px 15px 150px;
}

.at-form-section--heading {
  text-shadow: 0 4px 4px rgb(0 0 0 / 22%);
  margin-bottom: 30px;
}

.at-career--form {
  margin-bottom: 50px;
}

.at-career--form .form-group {
  position: relative;
}

.at-row,
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.at-career--form .form-group .at-form--icons {
  width: 20px;
  position: absolute;
  top: 14px;
  left: 30px;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.at-career--form .form-group .form-control {
  padding: 24px 0;
  padding-left: 65px;
  background: #fff;
  border-radius: 20px;
  border: initial;
  outline: initial !important;
  color: #47404f;
}

.at-career--form .at-textarea .at-form--icons {
  position: absolute;
  top: 24px;
}

.at-career-form-col img {
  margin: 0;
  width: 100%;
}



@media only screen and (min-width: 576px) {

}

@media only screen and (min-width: 768px) {
  .at-career__banner--heading {
    font-size: 50px;
  }
}

@media only screen and (min-width: 992px) {
  .at-career__banner-wrapper {
    padding-bottom: 100px;
    padding-top: 160px;
  }

  .at-career__banner--text {
    max-width: 430px;
  }

  .at-career-banner__img img {
    margin: 0 0 0 auto;
  }

  .at-career--hiring-wrapper {
    padding-bottom: 100px;
    padding-top: 80px;
  }

  .at-career__recruitment-wrapper {
    padding-top: 80px;
  }

  .at-recruitment-img {
    flex: 0 0 20%;
    max-width: 20%;
    margin: 0 50px 0 0;
  }

  .at-recruitment--content {
    flex: 0 0 35%;
    max-width: 35%;
  }

  .at-career-dots-right,
  .at-career-dots-left {
    display: block;
  }

  .at-career__recritment--process {
    background: transparent;
    box-shadow: none;
    padding: 0;
  }

  .at-career-form-wrapper {
    padding-bottom: 100px;
    padding-top: 80px;
  }

  .at-career--form {
    margin-bottom: 0;
  }

  .at-career-form-col img {
    margin: 0 0 0 auto;
    width: auto;
  }
}

@media only screen and (min-width: 1200px) {
}
