.at-contact__banner-wrapper {
  /* padding-bottom: 50px; */
  padding-top: 100px;
  background-color: #f7f9fb;
}

.at-contact__banner-wrapper .at-contact__bg-container .at-contact-banner__row {
  align-items: center;
}

.at-contact__banner-wrapper
  .at-contact__bg-container
  .at-contact-banner__row
  .at-contact-banner__content
  .at-contact__inner-content
  .at-contact__banner--heading {
  text-shadow: 0 4px 4px rgb(0 0 0 / 22%);
  margin-bottom: 20px;
  font-size: 42px;
}

.at-contact__banner-wrapper
  .at-contact__bg-container
  .at-contact-banner__row
  .at-contact-banner__content
  .at-contact__inner-content
  .at-contact__banner--text {
  margin-bottom: 30px;
  width: 100%;
}

.at-contact__banner-wrapper
  .at-contact__bg-container
  .at-contact-banner__row
  .at-contact-banner__img {
  margin-top: 50px;
}

.at-contact__banner-wrapper
  .at-contact__bg-container
  .at-contact-banner__row
  .at-contact-banner__img
  img {
  margin: 0 auto;
}

.at-contact-wrapper {
  padding-bottom: 50px;
  padding-top: 50px;
}

.at-contact-wrapper .at-contact-container .at-contact--heading {
  text-shadow: 0 4px 4px rgb(0 0 0 / 22%);
  margin-bottom: 20px;
  font-size: 42px;
  text-align: center;
}

.at-contact-wrapper .at-contact-container .at-contact--para {
  text-align: center;
}

.at-contact-us-form-wrapper {
  position: relative;
  padding: 50px 15px;
}

.at-contact-us-form-wrapper:before {
  position: absolute;
  content: "";
  background: #f7f9fb;
  top: 0;
  left: 0;
  bottom: 200px;
  right: 0;
  z-index: -2;
}

.at-contact-us-form-wrapper .at-contact-us-form-container {
  border-radius: 40px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgb(0 0 0 / 10%);
}

.at-contact-us-form-wrapper
  .at-contact-us-form-container
  .at-contact-us-form__row
  .at-contact-us-form__left {
  background-color: #47404f;
  position: relative;
  order: 2;
}

.at-contact-us-form-wrapper
  .at-contact-us-form-container
  .at-contact-us-form__row
  .at-contact-us-form__left
  .at-contact-form-inner--content {
  padding: 30px;
}

.at-contact-form-title {
  margin-bottom: 50px;
  color: #fff;
}

.at-contact-us-form-wrapper
  .at-contact-us-form-container
  .at-contact-us-form__row
  .at-contact-us-form__left
  .at-contact-form-inner--content
  .at-email-info-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.at-contact-us-form-wrapper
  .at-contact-us-form-container
  .at-contact-us-form__row
  .at-contact-us-form__left
  .at-contact-form-inner--content
  .at-email-info-wrap
  img {
  margin-right: 15px;
}

.at-contact-us-form-wrapper
  .at-contact-us-form-container
  .at-contact-us-form__row
  .at-contact-us-form__left
  .at-contact-form-inner--content
  .at-email-info-wrap
  .at-email {
  color: #ffc107;
  font-weight: 800;
  margin-bottom: 0;
}

.at-contact-us-form-wrapper
  .at-contact-us-form-container
  .at-contact-us-form__row
  .at-contact-us-form__left
  .at-contact-form-inner--content
  .at-phn-info-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 50px;
}

.at-contact-us-form-wrapper
  .at-contact-us-form-container
  .at-contact-us-form__row
  .at-contact-us-form__left
  .at-contact-form-inner--content
  .at-phn-info-wrap
  img {
  margin-right: 15px;
}

.at-contact-us-form-wrapper
  .at-contact-us-form-container
  .at-contact-us-form__row
  .at-contact-us-form__left
  .at-contact-form-inner--content
  .at-phn-info-wrap
  .at-phn {
  color: #ffc107;
  font-weight: 800;
  margin-bottom: 0;
}

.at-contact-us-form-wrapper
  .at-contact-us-form-container
  .at-contact-us-form__row
  .at-contact-us-form__left
  .at-contact-form-inner--content
  .at-button--transparent {
  display: block;
  margin-bottom: 80px;
}

.at-contact-us-form-wrapper
  .at-contact-us-form-container
  .at-contact-us-form__row
  .at-contact-us-form__left
  .at-contact-form-inner--content
  .at-form--social-icons-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.at-contact-us-form-wrapper
  .at-contact-us-form-container
  .at-contact-us-form__row
  .at-contact-us-form__left
  .at-contact-form-inner--content
  .at-form--social-icons-wrap
  img {
  margin-right: 20px;
}

.at-contact-us-form-wrapper
  .at-contact-us-form-container
  .at-contact-us-form__row
  .at-contact-us-form__left
  .at-contact-form-inner--content
  .at-form--pattern {
  position: absolute;
  right: 0;
  bottom: 0;
}

.at-contact-us-form-wrapper
  .at-contact-us-form-container
  .at-contact-us-form__row
  .at-contact-us-form__right {
  background-color: #fff;
  padding: 40px 60px;
}

.at-contact-us-form-wrapper
  .at-contact-us-form-container
  .at-contact-us-form__row
  .at-contact-us-form__right
  .at-contact-us--form-wrap
  .form-group {
  margin-bottom: 30px;
}

.at-contact-us-form-wrapper
  .at-contact-us-form-container
  .at-contact-us-form__row
  .at-contact-us-form__right
  .at-contact-us--form-wrap
  .form-group
  label {
  display: block;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.at-contact-us-form-wrapper
  .at-contact-us-form-container
  .at-contact-us-form__row
  .at-contact-us-form__right
  .at-contact-us--form-wrap
  .form-group
  .form-control {
  border: solid #c3cede;
  border-width: 0 0 1px;
  display: block;
  padding: 5px 0;
  width: 100%;
}

.at-contact-us-form-wrapper
  .at-contact-us-form-container
  .at-contact-us-form__row
  .at-contact-us-form__right
  .at-contact-us--form-wrap
  .form-group
  .at-your-choice {
  display: flex;
  flex-wrap: wrap;
}

.at-contact-us-form-wrapper
  .at-contact-us-form-container
  .at-contact-us-form__row
  .at-contact-us-form__right
  .at-contact-us--form-wrap
  .form-group
  .at-your-choice
  .at-form--choice-wrap {
  border: 1px solid #c3cede;
  border-radius: 40px;
  margin-right: 10px;
  margin-bottom: 15px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.at-contact-us-form-wrapper
  .at-contact-us-form-container
  .at-contact-us-form__row
  .at-contact-us-form__right
  .at-contact-us--form-wrap
  .form-group
  input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
  -webkit-appearance: none;
  appearance: none;
  bottom: 0;
  cursor: pointer;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

.at-contact-us-form-wrapper
  .at-contact-us-form-container
  .at-contact-us-form__row
  .at-contact-us-form__right
  .at-contact-us--form-wrap
  .form-group
  .at-your-choice
  .at-form--choice-wrap
  .at-form-contents {
  font-size: 14px;
  color: #1f1f1f;
  padding: 11px;
  font-weight: 600;
}

.at-contact-us-form-wrapper
  .at-contact-us-form-container
  .at-contact-us-form__row
  .at-contact-us-form__right
  .at-contact-us--form-wrap
  .form-group
  input[type="checkbox"]:checked
  + span {
  color: #ffc107 !important;
  transition: all 0.3s linear;
}

.at-contact-us-form-wrapper
  .at-contact-us-form-container
  .at-contact-us-form__row
  .at-contact-us-form__right
  .at-contact-us--form-wrap
  .form-group
  .form-control {
  border: solid #c3cede;
  border-width: 0 0 1px;
  display: block;
  padding: 5px 0;
  width: 100%;
}

.at-contact-us-form-wrapper
  .at-contact-us-form-container
  .at-contact-us-form__row
  .at-contact-us-form__right
  .at-contact-us--form-wrap
  .form-group
  textarea {
  height: 40px;
}

.at-contact-us-form-wrapper
  .at-contact-us-form-container
  .at-contact-us-form__row
  .at-contact-us-form__right
  .at-contact-us--form-wrap
  .at-contact-us-form-btn-wrap {
  text-align: right;
  width: 100%;
}

.at-contact-us-form-wrapper
  .at-contact-us-form-container
  .at-contact-us-form__row
  .at-contact-us-form__left
  .at-contact-form-inner--content
  .at-contact-form-subtitle,
.at-contact-us-form-wrapper
  .at-contact-us-form-container
  .at-contact-us-form__row
  .at-contact-us-form__left
  .at-contact-form-inner--content
  .at-contact-form-title {
  margin-bottom: 50px;
  color: #fff;
}
.at-contact-image-section img {
  width: 100%;
}

@media only screen and (min-width: 576px) {
}

@media only screen and (min-width: 768px) {
  .at-contact__banner-wrapper
    .at-contact__bg-container
    .at-contact-banner__row
    .at-contact-banner__content
    .at-contact__inner-content
    .at-contact__banner--heading {
    font-size: 50px;
  }

  .at-contact-us-form-wrapper
    .at-contact-us-form-container
    .at-contact-us-form__row
    .at-contact-us-form__right
    .at-contact-us--form-wrap
    .form-group
    .at-your-choice
    .at-form--choice-wrap {
    flex: 0 0 calc(50% - 10px);
  }
}

@media only screen and (min-width: 992px) {
  .at-contact__banner-wrapper {
    /* padding-bottom: 100px; */
    padding-top: 80px;
  }

  .at-contact__banner-wrapper
    .at-contact__bg-container
    .at-contact-banner__row
    .at-contact-banner__content
    .at-contact__inner-content
    .at-contact__banner--text {
    max-width: 430px;
  }

  .at-contact__banner-wrapper
    .at-contact__bg-container
    .at-contact-banner__row
    .at-contact-banner__img
    img {
    margin: 0 0 0 auto;
  }

  .at-contact-wrapper {
    padding-bottom: 50px;
    padding-top: 80px;
  }

  .at-contact-wrapper .at-contact-container .at-contact--heading {
    font-size: 50px;
  }

  .at-contact-us-form-wrapper {
    padding-bottom: 100px;
    padding-top: 80px;
  }

  .at-contact__banner-wrapper
    .at-contact__bg-container
    .at-contact-banner__row {
    align-items: center;
  }

  .at-contact-us-form-wrapper
    .at-contact-us-form-container
    .at-contact-us-form__row
    .at-contact-us-form__left {
    order: unset;
  }

  .at-contact-us-form-wrapper
    .at-contact-us-form-container
    .at-contact-us-form__row
    .at-contact-us-form__right
    .at-contact-us--form-wrap
    .form-group
    .at-your-choice
    .at-form--choice-wrap {
    flex: 0 0 calc(25% - 10px);
  }
}

@media only screen and (min-width: 1200px) {
}
