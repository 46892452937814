.at-about__banner-wrapper {
  padding-bottom: 50px;
  padding-top: 100px;
}

.at-about-banner__row {
  align-items: center;
}

.at-about__banner--heading {
  text-shadow: 0 4px 4px rgb(0 0 0 / 22%);
  margin-bottom: 20px;
  font-size: 42px;
}

.at-about__banner--text {
  margin-bottom: 30px;
  width: 100%;
}

.at-about-banner__img {
  margin-top: 50px;
}

.at-about__our-story-wrapper {
  padding-bottom: 50px;
  padding-top: 50px;
}

.at-about__our-story--heading {
  text-shadow: 0 4px 4px rgb(0 0 0 / 22%);
  margin-bottom: 40px;
  text-align: right;
}

.at-about__our-story-wrapper .at-our-story__bg {
  background-image: url("./../../images/our-story.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  position: relative;
  height: 20vh;
}

.at-our-stroy__bg--overlay {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(
    147.64deg,
    rgba(69, 162, 71, 0.486) 11.48%,
    rgba(40, 60, 134, 0.468) 77.48%
  );
}

.at-about-our-story--bg {
  background-image: url("./../../images/about-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  position: relative;
  border-radius: 40px;
  overflow: hidden;
  margin-top: -50px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 20px 30px;
}

.at-about-our-story--text {
  color: #fff;
  z-index: 9;
  line-height: 1.6;
}

.at-about-our-story__content-overlay {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(
    180deg,
    rgba(38, 50, 56, 0.7) 19.27%,
    rgba(38, 50, 56, 0.7) 44.79%,
    rgba(248, 217, 15, 0.35) 83.33%
  );
}

.at-about-our-story-imgs {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 50px;
}

.at-about-our-story-img2 {
  width: 100%;
  max-width: 200px;
}

.at-about-our-story-img1 {
  width: 100%;
  max-width: 200px;
}

.at-about__business-wrapper {
  padding-bottom: 50px;
  padding-top: 50px;
  background-color: #f7f9fb;
}

.at-about-business__img {
  flex: 0 0 50%;
}

.at-about-business__img img {
  width: 100%;
  padding: 0 20px;
}

.at-about-business__content {
  flex: 0 0 50%;
  margin-top: 50px;
}

.at-about__business-inner-content {
  padding: 0 15px;
}

.at-about__business--heading {
  text-shadow: 0 4px 4px rgb(0 0 0 / 22%);
  margin-bottom: 20px;
}

.at-about--cta-section {
  padding-bottom: 50px;
  padding-top: 50px;
}

.at-about--cat-row {
  align-items: center;
  position: relative;
}

.at-about-cta--left-section {
  margin-bottom: 20px;
}

.at-about--cta--heading {
  text-shadow: 0 4px 4px rgb(0 0 0 / 22%);
}

.at-cta-dotted-arrow {
  position: absolute;
  left: 30%;
  top: 40%;
  display: none;
}

.at-about-cta--right-section {
  text-align: right;
}

@media only screen and (min-width: 576px) {
  .at-about__our-story-wrapper .at-our-story__bg {
    height: 30vh;
  }

  .at-about-our-story--bg {
    padding: 50px 70px;
  }

  .at-services-inner-content {
    max-width: 520px;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 768px) {
  .at-about__banner--heading {
    font-size: 50px;
  }

  .at-about__our-story-wrapper .at-our-story__bg {
    height: 40vh;
  }

  .at-about-our-story-img2 {
    max-width: 300px;
  }

  .at-about-our-story-img1 {
    max-width: 300px;
  }

  .at-about__business-inner-content {
    max-width: 720px;
    margin: 0 auto;
  }

  .at-about-cta--left-section {
    margin-bottom: 40px;
  }

  .at-cta-dotted-arrow {
    max-width: 42%;
    display: block;
  }
}

@media only screen and (min-width: 992px) {
  .at-about__banner-wrapper {
    padding-top: 80px;
  }

  .at-about__banner--text {
    max-width: 430px;
  }

  .at-about__our-story-wrapper {
    padding-bottom: 100px;
    padding-top: 80px;
  }

  .at-about__our-story-wrapper .at-our-story__bg {
    height: 60vh;
  }

  .at-about-our-story-imgs {
    display: block;
    margin-top: 0;
  }

  .at-about-our-story-img2 {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .at-about__business-wrapper {
    padding-bottom: 100px;
    padding-top: 80px;
  }

  .at-about-business__img {
    max-width: 50%;
  }

  .at-about-business__img img {
    width: auto;
    padding: 0;
  }

  .at-about-business__content {
    max-width: 50%;
    margin-top: 0;
  }

  .at-about__business-inner-content {
    max-width: 570px;
    width: 100%;
    padding-left: 100px;
    padding-right: 15px;
    margin: 0;
  }

  .at-about--cta-section {
    padding-bottom: 100px;
    padding-top: 80px;
  }

  .at-about-cta--left-section {
    margin-bottom: 50px;
  }

  .at-cta-dotted-arrow {
    max-width: 48%;
  }

  .at-about-business__content {
    max-width: 50%;
    margin-top: 0;
  }

  .at-about-business__row {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
  }
}

@media only screen and (min-width: 1200px) {
  .at-cta-dotted-arrow {
    max-width: 100%;
  }
}
