.at-services__banner-wrapper {
  padding-bottom: 50px;
  padding-top: 50px;
  background-color: #f7f9fb;
}

.at-services__row {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.at-services__content {
  flex: 0 0 100%;
  margin-top: 50px;
}

.at-services-inner-content {
  padding: 0 15px;
}

.at-services--heading {
  text-shadow: 0 4px 4px rgb(0 0 0 / 22%);
  margin-bottom: 20px;
}

.at-services--text {
  margin-bottom: 30px;
  width: 100%;
  max-width: 430px;
}

.at-services__img {
  flex: 0 0 100%;
  margin-top: 50px;
}

.at-services__img img {
  width: 100%;
  padding: 0 20px;
}

.at-know-services-wrapper {
  padding-bottom: 50px;
  padding-top: 50px;
}

.at-know-services--heading {
  text-shadow: 0 4px 4px rgb(0 0 0 / 22%);
  margin-bottom: 80px;
  text-align: center;
}

.at-row,
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.at-know-services-inner {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.at-know-services-inner img {
  margin-top: -30px;
}

.at-know-services--title {
  padding: 0 0 0 20px;
}

.at-know-services--right {
  margin-top: 50px;
}

.at-our-work-wrapper {
  position: relative;
  padding-bottom: 50px;
  padding-top: 50px;
}

.at-our-work-wrapper:before {
  position: absolute;
  content: "";
  background: #f7f9fb;
  top: 282px;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -2;
}

.at-our-work-section--heading {
  text-shadow: 0 4px 4px rgb(0 0 0 / 22%);
  margin-bottom: 70px;
  text-align: center;
}

.at-our-work__listing {
  box-shadow: 0 4px 10px rgb(0 0 0 / 10%);
  border-radius: 16px;
  margin: 0 16px 20px;
  background-color: #fff;
}

.at-our-work__listing {
  box-shadow: 0 4px 10px rgb(0 0 0 / 10%);
  border-radius: 16px;
  margin: 0 16px 20px;
  background-color: #fff;
}

.at-our-work-wrapper
  .at-our-work-container
  .at-our-work-row
  .at-our-work__listing
  .at-our-work__inner-wrap
  .at-our-work__title {
  margin: 20px 0;
}

.at-services--cta-section {
  padding-bottom: 50px;
  padding-top: 50px;
}

.at-services--cta-section .at-services--cta-container .at-services--cat-row {
  align-items: center;
  position: relative;
}

.at-services--cta-section
  .at-services--cta-container
  .at-services--cat-row
  .at-services-cta--left-section {
  margin-bottom: 20px;
}

.at-services--cta--heading {
  text-shadow: 0 4px 4px rgb(0 0 0 / 22%);
}

.at-services--cta-section
  .at-services--cta-container
  .at-services--cat-row
  .at-cta-dotted-arrow {
  position: absolute;
  left: 30%;
  top: 40%;
  display: none;
}

.at-services--cta-section
  .at-services--cta-container
  .at-services--cat-row
  .at-services-cta--right-section {
  text-align: right;
}

@media only screen and (min-width: 576px) {
  .at-services-inner-content {
    max-width: 520px;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 768px) {
  .at-services-inner-content {
    max-width: 720px;
    margin: 0 auto;
  }

  .at-services--cta-section
    .at-services--cta-container
    .at-services--cat-row
    .at-services-cta--left-section {
    margin-bottom: 0px;
  }

  .at-services--cta-section
    .at-services--cta-container
    .at-services--cat-row
    .at-cta-dotted-arrow {
    max-width: 42%;
    display: block;
  }
}

@media only screen and (min-width: 992px) {
  .at-services__banner-wrapper {
    padding-bottom: 100px;
    padding-top: 80px;
  }

  .at-services__content {
    max-width: 50%;
    margin-top: 0;
  }

  .at-services-inner-content {
    max-width: 570px;
    width: 100%;
    padding-left: 100px;
    padding-right: 15px;
    margin: 0;
  }

  .at-services__img {
    max-width: 50%;
    margin-top: 0;
  }

  .at-services__img img {
    width: auto;
    padding: 0;
  }

  .at-know-services-wrapper {
    padding-bottom: 100px;
    padding-top: 80px;
  }

  .at-know-services--right {
    margin-top: 0;
  }

  .at-our-work-wrapper {
    padding-bottom: 100px;
    padding-top: 80px;
  }

  .at-our-work__listing {
    margin: 0 0 20px;
    flex: 0 0 calc(50% - 20px);
    max-width: calc(50% - 20px);
  }

  .at-our-work-wrapper
    .at-our-work-container
    .at-our-work-row
    .at-our-work__listing:nth-child(odd):nth-child(2n + 1) {
    margin-right: 20px;
  }

  .at-our-work-wrapper
    .at-our-work-container
    .at-our-work-row
    .at-our-work__listing:nth-child(odd) {
    margin: 0 0 20px;
    flex: 0 0 calc(50% - 20px);
    max-width: calc(50% - 20px);
  }

  .at-our-work__listing {
    margin: 0 0 20px;
    flex: 0 0 calc(50% - 20px);
    max-width: calc(50% - 20px);
  }

  .at-services--cta-section {
    padding-bottom: 100px;
    padding-top: 80px;
  }

  .at-services--cta-section
    .at-services--cta-container
    .at-services--cat-row
    .at-services-cta--left-section {
    margin-bottom: 20px;
  }

  .at-services--cta-section
    .at-services--cta-container
    .at-services--cat-row
    .at-cta-dotted-arrow {
    max-width: 48%;
  }
}

@media only screen and (min-width: 1200px) {
  .at-services--cta-section
    .at-services--cta-container
    .at-services--cat-row
    .at-cta-dotted-arrow {
    max-width: 100%;
  }
}
